import { memo, useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Menu, MenuItem, useMediaQuery } from "@mui/material";
import { AdminPanelSettingsOutlined, FilterTiltShift } from "@mui/icons-material";
import { ReactComponent as BigNotification } from "../assets/images/notification.svg";
import { ReactComponent as BigNotificationGreen } from "../assets/images/notification-green.svg";
import { ReactComponent as ArrowRight } from "../assets/images/arrow-right.svg";
import {
  getCountUnreadNotifications,
  getIsDemoMode,
  getUser,
  isSuperAdmin
} from "../store/auth/auth.selectors";
import { actions } from "../store/auth/auth.reducers";
import axios from "../axios";
import { ReactComponent as LogOut } from "../assets/images/home/login.svg";
import { ReactComponent as Setting } from "../assets/images/home/setting-2.svg";
import { ReactComponent as Help } from "../assets/images/home/message-question.svg";
import { ReactComponent as Profile } from "../assets/images/home/user.svg";
import { getBreadCrumbs } from "../store/bread-crumbs/bread-crumbs.selectors";
import { actions as breadCrumbsActions } from "../store/bread-crumbs/bread-crumbs.reducers";
import { actions as dataActions } from "../store/data/data.reducers";
import { actions as crumbsActions } from "../store/bread-crumbs/bread-crumbs.reducers";
import { actions as employeeActions } from "../store/employee/employee.reducers";
import { actions as insightsActions } from "../store/insights/insights.reducers";
import { actions as leaderboardActions } from "../store/leaderboard/leaderboard.reducers";
import { actions as organizationsActions } from "../store/organizations/organizations.reducers";
import { actions as payrollActions } from "../store/payroll/payroll.reducers";
import { actions as payrollReviewActions } from "../store/payroll-preview/payroll-preview.reducers";
import { actions as requestsActions } from "../store/pending-requests/pending-requests.reducers";
import Loading from "./common/Loading";
import dayjs from "dayjs";
import BlurText from "./common/BlurText";
import { defaultSettingPage, isUserSourceRestricted } from "../helpers/helpers";
import toastService from "../services/toastService";

const Header = ({ isOpen, setIsOpen }) => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorNotificationsEl, setAnchorNotificationsEl] = useState(null);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);

  const isImpersonateUser = localStorage.getItem("impersonateUserToken");
  const isImpersonateUserData = localStorage.getItem("impersonateUserData");

  const isUserSuperAdmin = useSelector(isSuperAdmin);
  const isDemoMode = useSelector(getIsDemoMode);

  const isMobile = useMediaQuery("(max-width:990px)");

  const { pathname } = useLocation();

  const open = Boolean(anchorEl);
  const openNotifications = Boolean(anchorNotificationsEl);

  const userData = useSelector(getUser);
  const countUnreadNotifications = useSelector(getCountUnreadNotifications);
  const breadCrumbs = useSelector(getBreadCrumbs);

  const isUserRestricted = isUserSourceRestricted();

  useEffect(() => {
    if (breadCrumbs.length > 0) {
      dispatch(breadCrumbsActions.resetBreadCrumbs());
    }
  }, [pathname]);

  useEffect(() => {
    if (openNotifications) {
      setIsNotificationsLoading(true);

      axios
        .get("/notifications/all")
        .then((response) => {
          if (response.success) {
            setNotificationsList(response.data);
          }
        })
        .catch((err) => toastService.error(err.response?.data?.message || err.message))
        .finally(() => setIsNotificationsLoading(false));
    }
  }, [openNotifications]);

  const handleLogout = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post("/auth/logout")
      .then(() => {
        batch(() => {
          dispatch(actions.logOutUser());
          dispatch(crumbsActions.resetBreadCrumbs());
          dispatch(dataActions.resetData());
          dispatch(employeeActions.resetEmployeeData());
          dispatch(insightsActions.resetInsightsData());
          dispatch(leaderboardActions.resetLeaderboardData());
          dispatch(organizationsActions.resetOrganizationsData());
          dispatch(payrollActions.resetPayroll());
          dispatch(payrollReviewActions.resetPayroll());
          dispatch(requestsActions.resetPendingRequestsData());
        });

        if (isImpersonateUser) {
          localStorage.removeItem("impersonateUserToken");
          localStorage.removeItem("impersonateUserData");
        }

        localStorage.removeItem("isDemoMode");
        localStorage.removeItem("token");
        window.Intercom("shutdown");
        nav("/login");
        return;
      })
      .catch((err) => {
        batch(() => {
          dispatch(actions.logOutUser());
          dispatch(crumbsActions.resetBreadCrumbs());
          dispatch(dataActions.resetData());
          dispatch(employeeActions.resetEmployeeData());
          dispatch(insightsActions.resetInsightsData());
          dispatch(leaderboardActions.resetLeaderboardData());
          dispatch(organizationsActions.resetOrganizationsData());
          dispatch(payrollActions.resetPayroll());
          dispatch(payrollReviewActions.resetPayroll());
          dispatch(requestsActions.resetPendingRequestsData());
        });

        if (isImpersonateUser) {
          localStorage.removeItem("impersonateUserToken");
          localStorage.removeItem("impersonateUserData");
        }

        localStorage.removeItem("isDemoMode");
        localStorage.removeItem("token");
        window.Intercom("shutdown");
        toastService.error(err.response?.data?.message || err.message);
        nav("/login");
        return;
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event) => setAnchorEl(event.currentTarget);

  const handleNotificationsClose = () => setAnchorNotificationsEl(null);
  const handleNotificationsOpen = (event) => setAnchorNotificationsEl(event.currentTarget);

  const handleProfileClick = () => {
    nav("/settings");
    handleClose();
  };

  const handleSettingsClick = () => {
    nav(`/settings?page=${defaultSettingPage()}`);
    handleClose();
  };

  const handleBreadCrumbClick = (el, index) => {
    if (breadCrumbs.length - 1 === index) {
      return;
    }

    if (breadCrumbs.length === 3) {
      dispatch(breadCrumbsActions.setBreadCrumbs(breadCrumbs.slice(0, 1)));
    }

    el.url && nav(el.url);
  };

  const handleLoginBackClick = () =>
    axios
      .post("/auth/logout")
      .then((response) => {
        if (response.success) {
          batch(() => {
            dispatch(actions.loginUser({}));
            dispatch(actions.setToken(isImpersonateUser));
            dispatch(dataActions.resetData());
            toastService.success("Impersonation logout success");
          });
          localStorage.setItem("user", isImpersonateUserData);
          localStorage.setItem("token", isImpersonateUser);
          localStorage.removeItem("impersonateUserToken");
          localStorage.removeItem("impersonateUserData");

          setTimeout(() => {
            nav(localStorage.getItem("impersonateBackLink"));
          }, 500);
          return;
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message));

  const handleAdminClick = () => {
    nav("/admin/organizations");
    handleClose();
  };

  const handleMarkAsRead = () => {
    setIsNotificationsLoading(true);

    axios
      .post("/notifications/mark-as-read")
      .then((response) => {
        if (response.success) {
          dispatch(actions.setUserUnreadNotificationsCount());
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsNotificationsLoading(false));
  };

  const newNotifications = notificationsList.filter((el) => !el.read_at?.length);
  const isNotificationsLength = !!countUnreadNotifications;
  const prevNotifications = notificationsList.filter((el) => el.read_at?.length);

  const unreadNotificationsLength = countUnreadNotifications > 9 ? "9+" : countUnreadNotifications;

  const handleMoveToDemoClick = () => {
    localStorage.setItem("isDemoMode", !isDemoMode);
    dispatch(actions.isDemoModeChange(!isDemoMode));
    handleClose();
  };

  const handleOpenOrCloseMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const isDemoModeFromStorage = localStorage.getItem("isDemoMode");

    if (isDemoModeFromStorage) {
      dispatch(actions.isDemoModeChange(isDemoModeFromStorage === "true" ? true : false));
    }
  }, []);

  useEffect(() => {
    if (openNotifications && isNotificationsLength) {
      handleMarkAsRead();
    }
  }, [openNotifications]);

  return (
    <div
      className="header-sec"
      style={{ justifyContent: breadCrumbs.length ? "space-between" : "flex-end" }}>
      {breadCrumbs.length > 0 && (
        <div className="header-sec-bread-crumbs">
          {breadCrumbs.map((el, index) => (
            <div key={el.name} className="header-sec-bread-crumb-wrapper">
              <div
                className={`header-sec-bread-crumb ${
                  breadCrumbs.length - 1 === index ? "active" : ""
                }`}
                onClick={handleBreadCrumbClick.bind(null, el, index)}>
                {index === 2 ? <BlurText>{el.name}</BlurText> : el.name}
              </div>
              {breadCrumbs.length - 1 !== index && (
                <ArrowRight className="header-sec-bread-crumb-arrow" />
              )}
            </div>
          ))}
        </div>
      )}

      {isMobile && (
        <div className="mobile-menu-bars" onClick={handleOpenOrCloseMenu}>
          <span className="line-1"></span>
          <span className="line-2"></span>
          <span className="line-3"></span>
        </div>
      )}

      <div className="header-box-r">
        {isImpersonateUser && (
          <button onClick={handleLoginBackClick} className="btn-style login-back">
            Login back
          </button>
        )}
        <button
          className={`notification-btn ${openNotifications ? "active" : ""} ${
            isNotificationsLength ? "activeWithoutBorder" : ""
          }`}
          onClick={handleNotificationsOpen}>
          <BigNotification />
          {isNotificationsLength && (
            <div>
              <p>{unreadNotificationsLength}</p>
            </div>
          )}
        </button>
        <button className="user-btn" onClick={handleOpen}>
          {userData.avatar ? (
            <img src={userData.avatar} alt="" />
          ) : (
            userData.first_name?.slice(0, 1)
          )}
        </button>
      </div>

      <Menu
        anchorEl={anchorNotificationsEl}
        open={openNotifications}
        onClose={handleNotificationsClose}
        className="header-menu-wrapper notifications">
        <div className="notifications-menu-header" style={{ justifyContent: "center" }}>
          <h2>Notifications</h2>
        </div>

        {isNotificationsLoading ? (
          <div className="notifications-menu-empty">
            <Loading />
          </div>
        ) : notificationsList.length ? (
          <div className="notifications-menu-content">
            {newNotifications.map((el) => (
              <div key={el.id} className="notifications-menu-item">
                <div className="notifications-menu-item-icon no-read">
                  <BigNotificationGreen />
                </div>

                <div className="notifications-menu-item-text">
                  <div>{el.data.message}</div>
                  <div className="notifications-menu-item-time">
                    {dayjs(el.created_at).format("MM/DD/YYYY")}
                  </div>
                </div>
              </div>
            ))}

            {Boolean(prevNotifications.length) && (
              <div>
                {Boolean(newNotifications.length) && (
                  <div className="notifications-menu-prev">
                    <div />
                    <h5>PREVIOUS NOTIFICATIONS</h5>
                    <div />
                  </div>
                )}

                {prevNotifications.map((el) => (
                  <div key={el.id} className="notifications-menu-item">
                    <div className="notifications-menu-item-icon">
                      <BigNotification />
                    </div>

                    <div className="notifications-menu-item-text">
                      <div>{el.data.message}</div>
                      <div className="notifications-menu-item-time">
                        {dayjs(el.created_at).format("MM/DD/YYYY")}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className="notifications-menu-empty">No new notifications</div>
        )}
      </Menu>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className="header-menu-wrapper">
        <div className="statement-first-block" style={{ paddingLeft: "8px" }}>
          <Avatar
            variant="circular"
            className="statement-avatar"
            alt={userData?.first_name}
            src={userData?.avatar}
          />
          <div className="statement-name-block">
            <div>
              {userData?.first_name} {userData?.last_name}
            </div>

            <div className="statement-company">{userData?.email}</div>
          </div>
        </div>
        <div className="header-menu-divider" />
        {!isUserRestricted && (
          <MenuItem className="header-menu-item" onClick={handleProfileClick}>
            <Profile className="header-logout-icon" />
            Profile
          </MenuItem>
        )}
        {isUserSuperAdmin && (
          <>
            <MenuItem className="header-menu-item" onClick={handleAdminClick}>
              <AdminPanelSettingsOutlined className="header-logout-icon" />
              Admin Panel
            </MenuItem>
            <MenuItem className="header-menu-item" onClick={handleMoveToDemoClick}>
              <FilterTiltShift className="header-logout-icon" />
              {isDemoMode ? "Exit Demo Mode" : "Move to Demo Mode"}
            </MenuItem>
          </>
        )}
        {!isUserRestricted && (
          <MenuItem className="header-menu-item" disabled>
            <Help className="header-logout-icon" />
            Help Center
          </MenuItem>
        )}
        <MenuItem className="header-menu-item" onClick={handleSettingsClick}>
          <Setting className="header-logout-icon" />
          {isUserRestricted ? "Custom Settings" : "Settings"}
        </MenuItem>
        {!isUserRestricted && (
          <>
            <div className="header-menu-divider" />
            <MenuItem onClick={handleLogout} disabled={loading} className="header-menu-item">
              <LogOut className="header-logout-icon" />
              Logout
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  );
};

export default memo(Header);

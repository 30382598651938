import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DarkTooltip from "../../components/common/DarkToolTip";
import { ReactComponent as Danger } from "../../assets/images/run-payroll-preview/danger.svg";
import { getUser } from "../../store/auth/auth.selectors";

const LeftMenuItem = ({ menuItem, isCollapsed }) => {
  const nav = useNavigate();
  const location = useLocation();
  const userData = useSelector(getUser);
  const { icon, name, url, mui, redirect } = menuItem;

  const ifActive = location.pathname === url;

  const handleNavigate = () => (redirect ? window.open(url, "_blank") : nav(url));

  const button = (
    <button
      onClick={handleNavigate}
      className={ifActive ? "active" : ""}
      style={{
        whiteSpace: "nowrap",
        width: isCollapsed ? "52px" : "100%",
        margin: isCollapsed ? "auto" : "0",
        position: "relative"
      }}>
      {mui ? icon : <img src={icon} alt="" />}
      {isCollapsed && userData.has_missing_employees && name === "Employees" && (
        <Danger className="collapsed-danger" />
      )}

      {!isCollapsed && name}
      {!isCollapsed && userData.has_missing_employees && name === "Employees" && (
        <Danger style={{ marginLeft: "auto" }} className="danger" />
      )}
    </button>
  );

  return (
    <li>
      {isCollapsed ? (
        <DarkTooltip
          placement="right"
          title={name}
          styles={{
            right: "-10px",
            padding: "10px",
            marginLeft: "5px!important"
          }}>
          {button}
        </DarkTooltip>
      ) : (
        button
      )}
    </li>
  );
};

export default LeftMenuItem;
